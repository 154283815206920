import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";

const ContactCtaContainer = styled(Link)`
    position: fixed;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 10%;
    width: 100px;
    padding: .5em .25em .5em .5em;
    background: ${colors.blue600};
    color: #fff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.5);
    text-align: right;
    text-decoration: none;
    font-weight: bold;
    z-index: 1;

    &:hover,
    &:focus {
        transition: all 150ms ease-in-out;
        width: 105px;

        span {
            transform: translateX(-3px);
            transition: transform 150ms ease-in-out;
        }
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        width: 90px;

        &:hover,
        &:focus {
            width: 95px;
        }
    }

    span {
        padding-left: .5em;
        transform: translateX(-8px);
        margin-left: .5em;
    }
`

class ContactCTA extends Component {
    render() {
        return (
            <ContactCtaContainer to="/contact">
                Contact
                <span>&#8594;</span>
            </ContactCtaContainer>
        );
    }
}

export default ContactCTA;